import React, { useState } from 'react';
import { SignUpModal } from '../../../shared';

import { MilanCredit } from '../../../specials';

export default function MilanCreditBuilder({ siteData }) {
    const [showSignUpModal, setShowSignUpModal] = useState(false);

    return (
        <div className="hide-disc">
            {showSignUpModal && <SignUpModal {...{ siteData, showSignUpModal, setShowSignUpModal }} />}
            <MilanCredit setShowSignUpModal={setShowSignUpModal} globalData={siteData?.globalData} />
        </div>
    );
}
