import React from 'react';
import { LPCredit } from '../../../lp';

export default function CreditApproval({ siteData }) {
    return (
        <div id="social-page" className="hide-disc">
            <LPCredit globalData={siteData?.globalData} />
        </div>
    );
}
